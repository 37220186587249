var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionnaire-result"},[_c('router-menu-title',{attrs:{"routerTitleList":_vm.routerTitleList}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.allLoading),expression:"allLoading"}],staticClass:"container-wrap"},[_c('xm-table',{ref:"xmTable",attrs:{"fatch-method":_vm.fatchMethod,"form-items":_vm.formItems,"columns":_vm.columns,"formOptions":{
        hiddenDefaultFormBtn: true,
      },"autoLoad":true,"tableOptions":{
        height: _vm.tableHeight,
        customCellStyle: false,
        headerStyle: _vm.headerStyle,
      },"row-key":_vm.getRowKeys,"noEmptyText":"el.common.noData"},on:{"changeQueryParams":_vm.changeQueryParams},scopedSlots:_vm._u([{key:"otherHandle",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.showDetails(scope.row)}}},[_vm._v(_vm._s(_vm.$t("el.planCourse.view")))])]}}])},[_c('template',{slot:"formBtn"},[_c('div',{staticClass:"query-btn"},[_c('el-button',{staticClass:"teachManage-tool-btnicon",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.query()}}},[_vm._v(_vm._s(_vm.$t("el.common.screening")))]),_c('el-button',{staticClass:"teachManage-tool-btnicon",attrs:{"size":"small"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(_vm._s(_vm.$t("el.common.reset")))])],1)])],2)],1),(_vm.recordDialogVisible)?_c('record-dialog',{attrs:{"dialog-visible":_vm.recordDialogVisible,"dialog-title":_vm.$t('el.planCourse.viewQuesResult'),"id":_vm.resultId},on:{"handleClose":_vm.handleCloseFn}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }