<template>
  <el-dialog
    :before-close="handleClose"
    :visible.sync="dialogVisible"
    :title="dialogTitle"
    width="50%"
    custom-class="dialog-com-import-record"
    append-to-body
  >
    <div class="dialog-import-record">
      <p class="import-record-des">
        {{
          $t("el.courseClassManage.studentName") +
          ":" +
          (studentInfo.questionnaireSname || "-") +
          "&nbsp;&nbsp;" +
          $t("el.courseClassManage.studentNumber") +
          ":" +
          (studentInfo.questionnaireSno || "-") +
          "&nbsp;&nbsp;" +
          $t("el.courseClassManage.InClass") +
          ":" +
          (studentInfo.questionnaireSclassName || "-")
        }}
      </p>
      <div class="import-record-form">
        <xm-table
          ref="xmTable"
          :fatch-method="fatchMethod"
          :columns="columns"
          :hideForm="true"
          :formOptions="{
            hiddenDefaultFormBtn: true,
          }"
          :autoLoad="true"
          :tableOptions="{
            height: tableHeight,
          }"
          :row-key="getRowKeys"
          noEmptyText="el.common.noData"
        >
        </xm-table>
      </div>
      <div class="import-record-table"></div>
    </div>
  </el-dialog>
</template>

<script>
import { importRecord } from "@/api/user";
import { handparams } from "@/utils";
import { getQuestionnairefeedback } from "@/api/manage/questionnaire-result.js";

export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tableHeight: undefined,
      importPositionOptions: [],
      studentInfo: {},
    };
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    handleClose() {
      this.$emit("handleClose");
    },
    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      params.questionnaireUserId = this.id;
      let data = handparams(params);
      return getQuestionnairefeedback(data)
        .then((result) => {
          this.studentInfo = result.data;
          return { data: result.data.page };
        })
        .catch(() => {
          return null;
        });
    },
  },
  created() {},
  computed: {
    /**
     * 定义列
     */

    columns() {
      return [
        {
          label: this.$t("el.planCourse.CourseName"),
          prop: "questionnaireSubject",
        },
        {
          label: this.$t("el.planCourse.satisfaction"),
          prop: "questionnaireAnswer",
        },
      ];
    },
  },
};
</script>
<style lang="less">
.dialog-com-import-record {
  min-width: 730px;
  max-width: 92%;
  max-height: 92%;
  overflow-y: auto;
  .el-dialog__header {
    padding: 20px 28px;
    display: flex;
  }
  .el-dialog__body {
    padding: 0 28px 30px;
    text-align: left;
    .dialog-import-record {
      .import-record-des {
        text-align: left;
      }
    }
    .import-record-form {
      margin-top: 20px;
      width: 100%;
      .el-form-item {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
