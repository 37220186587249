/**
 * 问卷管理-问卷结果
 */
import axios from "@/plugins/axios.js";

/**
 * 获取问卷结果列表
 * @return {Promise}
 */
export function getQuestionnaireuserList(data) {
  return axios.post("/questionnaire/management/page/questionnaireuser", data);
}

/**
 * 查看文件结果详情
 * @return {Promise}
 */
export function getQuestionnairefeedback(data) {
  return axios.post(
    "/questionnaire/management/page/questionnairefeedback",
    data
  );
}

/**
 * 查询意向活动课程类型
 * @return {Promise}
 */
export function getSelectDictionary() {
  return axios.get("/questionnaire/management/questionnaireCourseType");
}

/**
 * 查询年级列表
 * @return {Promise}
 */
export function getGradeList(data) {
  return axios.post(
    "/questionnaire/management/grade/" + data.questionnaireManagementId
  );
}

/**
 * 查询班级列表
 * @return {Promise}
 */
export function getClassList(data) {
  return axios.post(
    "/questionnaire/management/class/" + data.questionnaireManagementId,
    data.params
  );
}

/**
 * 某个问卷下 查询意向活动课程类型
 * @return {Promise}
 */
export function questionnaireCourseType(questionnaireId) {
  return axios.get(
    `/questionnaire/management/questionnaireCourseType/${questionnaireId}`
  );
}
