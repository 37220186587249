<template>
  <div class="questionnaire-result">
    <router-menu-title :routerTitleList="routerTitleList"></router-menu-title>
    <!-- 问卷结果列表 -->
    <div v-loading="allLoading" class="container-wrap">
      <xm-table
        ref="xmTable"
        :fatch-method="fatchMethod"
        :form-items="formItems"
        :columns="columns"
        :formOptions="{
          hiddenDefaultFormBtn: true,
        }"
        :autoLoad="true"
        :tableOptions="{
          height: tableHeight,
          customCellStyle: false,
          headerStyle: headerStyle,
        }"
        @changeQueryParams="changeQueryParams"
        :row-key="getRowKeys"
        noEmptyText="el.common.noData"
      >
        <template slot="formBtn">
          <div class="query-btn">
            <el-button
              type="primary"
              size="small"
              class="teachManage-tool-btnicon"
              @click="query()"
              >{{ $t("el.common.screening") }}</el-button
            >
            <el-button
              size="small"
              class="teachManage-tool-btnicon"
              @click="resetForm()"
              >{{ $t("el.common.reset") }}</el-button
            >
            <!-- <import-tools
              :downHref="baseUrl + '/questionnaire/management/download'"
              :action="baseUrl + '/questionnaire/management/import/' + id"
              :elementLoadingText="$t('el.planCourse.importing') + '...'"
              @handleSuccess="handleSuccessImport"
            /> -->
          </div>
        </template>

        <template slot="otherHandle" slot-scope="scope">
          <el-button type="text" size="small" @click="showDetails(scope.row)">{{
            $t("el.planCourse.view")
          }}</el-button>
        </template>
      </xm-table>
    </div>
    <record-dialog
      v-if="recordDialogVisible"
      :dialog-visible="recordDialogVisible"
      :dialog-title="$t('el.planCourse.viewQuesResult')"
      :id="resultId"
      @handleClose="handleCloseFn"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import routerMenuTitle from "@/components/biz-components/router-menu-title/index";
import {
  getQuestionnaireuserList,
  getSelectDictionary,
  getGradeList,
  getClassList,
  questionnaireCourseType,
} from "@/api/manage/questionnaire-result.js";
// import ImportTools from "@/components/import-tools/index.vue";
import RecordDialog from "./component/resultDialog.vue";

export default {
  name: "questionnaireResult",
  components: {
    routerMenuTitle,
    // ImportTools,
    RecordDialog,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      id: this.$route.query.id,
      tableHeight: undefined,
      gradeList: [],
      classList: [],
      activityCourseTypeList: [],
      allLoading: true,
      newQuestionnaireSgrade: "",
      recordDialogVisible: false,
      resultId: "",
    };
  },
  async created() {
    this.init();
  },
  // 计算属性
  computed: {
    routerTitleList() {
      return [
        {
          name: this.$t("el.planCourse.questionnaireManage"),
        },
        {
          name: this.$t(this.$route.meta.title),
        },
      ];
    },
    /**
     * 定义列
     */
    columns() {
      let that = this;
      return [
        {
          label: this.$t("el.courseClassManage.studentNumber"),
          prop: "questionnaireSno",
          // labelRule: 2,
          lineRule: true,
          showtooltip: "false",
          width: "150px",
          fixed: "left",
        },
        {
          label: this.$t("el.courseClassManage.studentName"),
          prop: "questionnaireSname",
          // labelRule: 2,
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        {
          label: this.$t("el.planCourse.inGrade"),
          prop: "questionnaireSgradeName",
          // labelRule: 2,
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        {
          label: this.$t("el.courseClassManage.InClass"),
          prop: "questionnaireSclassName",
          // labelRule: 2,
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        {
          label: this.$t("el.planCourse.submitPerson"),
          prop: "submitName",
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        {
          label: this.$t("el.forgotPwd.MobileNumber"),
          prop: "submitPhone",
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        {
          label: this.$t("el.planCourse.submitTime"),
          prop: "submitTime",
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        {
          label: this.$t("el.planCourse.activityCourseType"),
          prop: "questionnaireCourseTypeName",
          // labelRule: 2,
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        {
          label: this.$t("el.planCourse.requirementType"),
          prop: "questionnaireIntentionType",
          formatter(row, column, value) {
            return value == 1
              ? that.$t("el.planCourse.other")
              : value == 0
              ? that.$t("el.planCourse.AddNewClass")
              : "";
          },
          // labelRule: 2,
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        {
          label: this.$t("el.planCourse.otherIntentionalNeeds"),
          prop: "questionnaireIntentionExtend",
          // labelRule: 2,
          lineRule: true,
          showtooltip: "false",
          width: "150px",
        },
        // {
        //   label: this.$t("el.planCourse.courseSatisfaction"),
        //   slotName: "otherHandle",
        //   width: this.$t("el.planCourse.operateWidth"),
        //   showtooltip: "false",
        //   fixed: "right",
        // },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "input",
          prop: "questionnaireStudentNo",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseClassManage.studentNumber"),
        },
        {
          itemType: "input",
          prop: "questionnaireSname",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseClassManage.studentName"),
        },
        {
          itemType: "select",
          prop: "questionnaireSgrade",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.inGrade"),
          clearable: true,
          options: this.gradeList,
        },
        {
          itemType: "select",
          prop: "questionnaireSclass",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.courseClassManage.InClass"),
          clearable: true,
          options: this.classList,
        },
        {
          itemType: "daterange",
          prop: "_questionnaireTime",
          prop1: "questionnaireBeginTime",
          prop2: "questionnaireEndTime",
          // "start-placeholder": this.$t("el.planCourse.releaseDate"),
          // "end-placeholder": this.$t("el.planCourse.deadline"),
          label: this.$t("el.planCourse.fillTime"),
          labelWidth: this.$t("el.common.width120_240"),
          "value-format": "yyyy-MM-dd",
        },
        {
          itemType: "select",
          prop: "questionnaireCourseType",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.activityCourseType"),
          clearable: true,
          options: this.activityCourseTypeList,
        },
        // {
        //   itemType: "cascader",
        //   prop: "questionnaireCourseType",
        //   size: "small",
        //   options: this.activityCourseTypeList,
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.planCourse.activityCourseType"),
        //   clearable: true,
        //   "popper-append-to-body": false,
        //   props: {
        //     value: "dictionaryKey",
        //     label: "dictionaryText",
        //     children: "children",
        //     // multiple: true,
        //   },
        // },
        {
          itemType: "select",
          prop: "questionnaireIntentionType",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.requirementType"),
          clearable: true,
          options: [
            {
              label: this.$t("el.planCourse.AddNewClass"),
              value: 0,
            },
            {
              label: this.$t("el.planCourse.other"),
              value: 1,
            },
          ],
        },
      ];
    },
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    handleCloseFn() {
      this.recordDialogVisible = false;
      this.resultId = "";
    },
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    async init() {
      await this.getGradeList();
      await this.getClassList();
      await this.getActivityCourseTypeList();
    },

    /**
     * 筛选
     */
    query() {
      if (this.$refs["xmTable"]) {
        this.allLoading = true;
        this.$refs["xmTable"].query();
      }
    },
    // 如果select更改
    async changeQueryParams(val) {
      if (val.questionnaireSgrade) {
        if (val.questionnaireSgrade !== this.newQuestionnaireSgrade) {
          this.newQuestionnaireSgrade = val.questionnaireSgrade;
          this.$refs["xmTable"].setFormValue("questionnaireSclass", "");
          await this.getClassList();
        }
      } else {
        if (
          val.questionnaireSgrade !== this.newQuestionnaireSgrade &&
          val.questionnaireSgrade != undefined
        ) {
          this.newQuestionnaireSgrade = val.questionnaireSgrade;
          this.$refs["xmTable"].setFormValue("questionnaireSclass", "");
          await this.getClassList();
        }
      }
    },

    // 排课已完成-查看排课详情，会跳到之后的新页面 查看课表
    showDetails(data) {
      this.recordDialogVisible = true;
      this.resultId = data.id;
    },

    /**
     * 重置
     */
    resetForm() {
      this.$refs["xmTable"].resetForm();
      this.getClassList();
    },

    // 获取所在年级列表
    async getGradeList() {
      getGradeList({ questionnaireManagementId: this.id })
        .then((res) => {
          if (res.status == 200) {
            res.data.map((item) => {
              this.gradeList.push({
                label: item.gradeName,
                value: item.gradeId,
              });
            });
          }
        })
        .catch(() => {});
    },

    // 获取所在班级列表
    async getClassList() {
      let data = {
        questionnaireManagementId: this.id,
        params: {
          gradeId: this.$refs["xmTable"].getFormValue("questionnaireSgrade"),
        },
      };
      getClassList(data)
        .then((res) => {
          if (res.status == 200) {
            this.classList = [];
            res.data.map((item) => {
              this.classList.push({
                label: item.className,
                value: item.classId,
              });
            });
          }
        })
        .catch(() => {});
    },

    // 获取意向活动课程类型列表
    async getActivityCourseTypeList() {
      questionnaireCourseType(this.$route.query.id)
        .then((res) => {
          if (res.status == 200) {
            res.data.map((item) => {
              this.activityCourseTypeList.push({
                label: item.dictionaryText,
                value: item.dictionaryKey,
              });
            });
          }
        })
        .catch(() => {});
    },

    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      this.allLoading = true;
      delete params.total;
      delete params.page;
      delete params._questionnaireTime;
      if (params.questionnaireBeginTime) {
        params.questionnaireBeginTime += " 00:00:00";
      }
      if (params.questionnaireEndTime) {
        params.questionnaireEndTime += " 23:59:59";
      }
      params.questionnaireManagermentId = this.id;
      return getQuestionnaireuserList(this.handparams(params))
        .then((result) => {
          this.allLoading = false;
          return result;
        })
        .catch(() => {
          this.allLoading = false;
        });
    },

    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      return params;
    },
    handleSuccessImport() {
      this.query();
    },
  },
};
</script>

<style lang="less" scoped>
.questionnaire-result {
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  .container-wrap {
    background: #ffffff;
    padding: 30px 50px;
    border-radius: 10px;

    .query-btn {
      display: flex;
    }

    /deep/ .el-form-item__content {
      line-height: 0;
    }

    /deep/ .el-button {
      padding: 9px 15px;
      border-radius: 5px;
      font-size: 12px;
      height: 32px;
      padding-left: 12px;
      padding-right: 12px;
    }
    /deep/ .el-button--default,
    .el-button--default:hover {
      color: #6049ff;
      background-color: #fff;
      border-color: #6049ff !important;
    }

    // /deep/ .el-button--small,
    // .el-button--small.is-round {
    //   padding: 9px 15px;
    //   border-radius: 5px;
    //   font-size: 12px;
    //   height: 32px;
    //   padding-left: 12px;
    //   padding-right: 12px;
    // }
    /deep/ .el-date-editor--daterange.el-input__inner {
      width: 230px;
      height: 40px;
    }
    /deep/ .el-range-editor--small .el-range-separator {
      line-height: 32px;
    }
    // /deep/ .xm-table .bskCell .cell {
    //   height: 40px;
    //   white-space: pre-wrap;
    //   display: -webkit-box;
    // }
    /deep/ .el-button--default,
    .el-button--default:hover {
      color: #6049ff;
      background-color: #fff;
      border-color: #6049ff !important;
    }
    /deep/ .xm-table .el-table__row {
      td {
        height: 65px;
      }
      .two-line-cell:first-child,
      .two-line-cell:nth-child(2) {
        height: 65px;
        overflow: visible;
        .cell.el-tooltip {
          width: 100%;
          line-height: 20px;
          height: 40px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          white-space: unset;
          span {
            height: 40px;
            display: block;
          }
          .more-line-height {
            line-height: 38px;
          }
        }
      }
    }
  }
  /deep/ .el-dialog {
    margin-top: 24 px !important;
    margin-bottom: 24 px !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: left;
  }
}
</style>
